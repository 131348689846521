<template>
  <section id="AllegiantStadiumLayout">
    <EventSpacesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import EventSpacesCarousel from '@/components/global/EventSpacesCarousel'
  import { getLayoutImages } from '@/data/sectionData/eventSpaces/AllegiantStadium'

  export default {
    name: "AllegiantStadiumLayout",
    components: {
      EventSpacesCarousel
    },
    data() {
      return {
        carouselImages: getLayoutImages()
      }
    }
  };
</script>

<style lang="scss" scoped>

</style>